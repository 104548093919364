// eslint-disable-next-line restrict-imports/restrict-imports
import { Cache as LegacyCache } from '../../../background/cache.platform';
import type { RxDBInstance } from '../../../types/database';
import makeLogger from '../../../utils/makeLogger';
import getDatabaseName from '../getDatabaseName';
import { migrationStartedKey, StorageVersion } from './constants';
import {
  detectDatabaseStorageVersion,
  finishStorageMigration as finishStorageMigrationForPlatform,
  startStorageMigration as startStorageMigrationForPlatform,
} from './helpers.platform';

const logger = makeLogger(__filename);

async function shouldMigrateStorage(expectedVersion: StorageVersion) {
  const databaseName = getDatabaseName();
  const detectedVersion = await detectDatabaseStorageVersion(databaseName, 'state_syncing_updates');
  return expectedVersion > detectedVersion;
}

export async function startStorageMigration() {
  const shouldMigrate = await shouldMigrateStorage(StorageVersion.Rxdb15);
  if (!shouldMigrate) {
    logger.debug('no storage migration needed');
    return;
  }

  const migrationStarted = await LegacyCache.getItem(migrationStartedKey);
  if (migrationStarted) {
    logger.debug('ongoing migration, do not start a new migration process');
    return;
  }

  logger.debug('start migration of storage');

  return startStorageMigrationForPlatform();
}

export async function finishStorageMigration(db: RxDBInstance) {
  logger.debug('finish storage migration');
  return finishStorageMigrationForPlatform(db);
}
