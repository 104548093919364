import EventEmitter2 from 'eventemitter2';
import type { RxStorage } from 'rxdb';
import { createRxDatabase, removeRxDatabase } from 'rxdb';
import * as rxAjv from 'rxdb/plugins/validate-ajv';
import * as rxZSchema from 'rxdb/plugins/validate-z-schema';

/* eslint-disable import/no-cycle, restrict-imports/restrict-imports */
import migrateStateSyncingFromLocalForage from '../background/database/migrateStateSyncingFromLocalForage';
import migrateStateSyncingToNewPersistentStateBlob from '../background/database/migrateStateSyncingToNewPersistentStateBlob';
/* eslint-enable import/no-cycle, restrict-imports/restrict-imports */
import type { DatabaseCollection, RxCollections, RxDBInstance } from '../types/database';
import type {
  HandleStateUpdateSideEffectsParameter,
  HandleStateUpdateSideEffectsResult,
} from '../types/stateUpdates';
import { DeferredPromise } from '../utils/DeferredPromise';
import {
  isDevOrTest,
  isDocumentShareApp,
  isExtension,
  isMobile,
  isTest,
  isUsingSQLite,
  isWebApp,
} from '../utils/environment';
import type { MaybePromise } from '../utils/typescriptUtils';
import clearLocalRxdbData from './clearLocalRxdbData.platform';
import defineSchema, { type schema } from './internals/defineSchema';
import getDatabaseCollectionFromRxCollection from './internals/getDatabaseCollectionFromRxCollection';
import getDatabaseName from './internals/getDatabaseName';
import getRxStorage from './internals/getRxStorage.platformIncludingExtension';
import logger from './internals/logger';
import addMiddleware from './internals/middleware/addMiddleware';
import { finishStorageMigration, startStorageMigration } from './internals/migration';
import setUpPlugins from './internals/setUpPlugins';
import { wrappedSQLiteDocumentsStorage } from './SQLiteDocumentsStorageWrapper';

type Collections = {
  [key in keyof typeof schema]: DatabaseCollection<key>;
};

type Options = {
  additionalMiddleware?: (database: Database) => MaybePromise<void>;
  allowSlowCount?: boolean;
  eventEmitter: EventEmitter2;
  handleStateUpdateSideEffects: (
    arg: HandleStateUpdateSideEffectsParameter,
  ) => HandleStateUpdateSideEffectsResult;
  // NOTE: in the web app, Database is instantiated once for both background and foreground
  isInForeground: boolean;
};

export default class Database {
  collections: Collections = this._getCollections();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  collections__force: Collections = this._getCollections(true);
  rxDbInstance: RxDBInstance | null = null;

  _databaseName = getDatabaseName();
  _initializationPromise: DeferredPromise<RxDBInstance> | null = null;
  _options: Options;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _storage: RxStorage<any, any> | null = null;

  constructor(options: Options) {
    logger.debug('Constructor');
    this._options = options;
    setUpPlugins();
  }

  assignOptions(options: Partial<Options>): void {
    Object.assign(this._options, options);
  }

  async clearUninitializedDb() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return removeRxDatabase(this._databaseName, this._storage);
  }

  async clear() {
    if (this._initializationPromise) {
      await this._initializationPromise;
    }
    if (!this.rxDbInstance || !this._storage) {
      this.rxDbInstance = await this._createDatabase();
    }
    if (!this._storage) {
      throw Error("Somehow storage cannot be created for db, can't clear it.");
    }

    await removeRxDatabase(this._databaseName, this._storage);

    await this.destroyRxDBInstance();

    await clearLocalRxdbData();

    logger.debug('Cleared');
  }

  async destroyRxDBInstance() {
    if (!this.rxDbInstance) {
      return;
    }
    this._initializationPromise = null;
    const promise = this.rxDbInstance.destroy();
    this.rxDbInstance = null;
    try {
      await promise;
    } catch (error) {
      logger.error('Error destroying RxDB instance', { error });
    }
  }

  getRxDBInstance() {
    if (!this.rxDbInstance) {
      throw new Error('Database not initialized');
    }
    return this.rxDbInstance;
  }

  shouldMigrateOldStateStorage() {
    return (!isExtension && !isDocumentShareApp) || !this._options.isInForeground;
  }

  async initialize({
    onSchemaConflictError,
  }: { onSchemaConflictError?: () => void } = {}): Promise<RxDBInstance> {
    if (this._initializationPromise) {
      return this._initializationPromise;
    }

    this._initializationPromise = new DeferredPromise<RxDBInstance>();

    /*
     * Start storage migration code before we start the database, as we can't
     * properly tear down a database created with the wrong storage.
     */
    const isClientAllowedToMigrateStorage = !(isExtension && this._options.isInForeground) && !isTest;
    if (isClientAllowedToMigrateStorage) {
      try {
        await startStorageMigration();
      } catch (error) {
        logger.error('Starting the migration failed', { error });
        this._initializationPromise.reject(error as Error);
        throw error;
      }
    }

    try {
      this.rxDbInstance = await this._createDatabase();
    } catch (error) {
      logger.error('Creation failed', { error });
      this._initializationPromise.reject(error as Error);
      throw error;
    }

    try {
      await defineSchema(this.rxDbInstance, this._options.isInForeground);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logger.error('Error thrown while defining schema', { error });
      if ((isWebApp || isMobile) && error.message.includes('RxError (DB6)') && onSchemaConflictError) {
        onSchemaConflictError();
        return this._initializationPromise;
      }

      this._initializationPromise.reject(error as Error);
      throw error;
    }

    await addMiddleware(this, this._options.additionalMiddleware);

    if (this.shouldMigrateOldStateStorage()) {
      try {
        await migrateStateSyncingFromLocalForage({
          rxDBInstance: this.rxDbInstance,
        });
      } catch (error) {
        logger.warn('Error thrown while migrating from localForage, ignoring...', { error });
      }

      try {
        await migrateStateSyncingToNewPersistentStateBlob(this);
      } catch (error) {
        logger.error('Error thrown while migrating from persistentStateBlob to documents', { error });
        throw error;
      }
    }

    // finish ongoing storage migration
    if (isClientAllowedToMigrateStorage) {
      try {
        await finishStorageMigration(this.rxDbInstance);
      } catch (error) {
        logger.error('Finishing the migration failed', { error });
        this._initializationPromise.reject(error as Error);
        throw error;
      }
    }

    this._initializationPromise.resolve(this.rxDbInstance);
    setTimeout(() => this._options.eventEmitter.emit('database-initialized'));
    return this.rxDbInstance;
  }

  isInitialized(): boolean {
    return this._initializationPromise?.status === 'resolved';
  }

  async _createDatabase() {
    this._storage = getRxStorage({
      eventEmitter: this._options.eventEmitter,
      isInForeground: this._options.isInForeground,
    });

    // We must wrap our custom SQLite storage here before applying key compression, or else we query on uncompressed keys.
    if (isUsingSQLite) {
      this._storage = wrappedSQLiteDocumentsStorage({ storage: this._storage });
    }

    if (
      isDevOrTest &&
      !isExtension // This has internal dynamic imports which isn't compatible with the extension build system
    ) {
      /*
        wrappedValidateAjvStorage doesnt work with hermes currently, we want to use ajv validation when it gets fixed.
        See https://github.com/ajv-validator/ajv/issues/1945
      */
      if (isMobile) {
        this._storage = rxZSchema.wrappedValidateZSchemaStorage({ storage: this._storage });
      } else {
        this._storage = rxAjv.wrappedValidateAjvStorage({ storage: this._storage });
      }
    }

    const minute = 1000 * 60;
    const month = minute * 60 * 24 * 31;

    const result = await createRxDatabase<RxCollections>({
      allowSlowCount: true,
      cleanupPolicy: {
        // https://rxdb.info/cleanup.html
        // Minimum time since item was deleted
        minimumDeletedTime: month,
        // Delay before first run
        minimumCollectionAge: 2 * minute,
        // Delay before subsequent runs
        runEach: 5 * minute,
        awaitReplicationsInSync: true,
        waitForLeadership: true,
      },
      eventReduce: true,
      ignoreDuplicate: false,
      multiInstance: !isMobile && !isTest,
      name: this._databaseName,
      storage: this._storage,
    });
    logger.debug('Created');
    return result;
  }

  _getCollections(force?: boolean): Collections {
    return new Proxy(
      {},
      {
        get: (target, propertyName: string) => {
          if (!this.isInitialized() && !force) {
            throw new Error('database is not initialized (yet?)');
          }
          if (!this.rxDbInstance) {
            throw new Error('this.rxDbInstance undefined');
          }
          if (!this.rxDbInstance.collections[propertyName]) {
            throw new Error(`this.rxDbInstance.collections.${propertyName} does not exist`);
          }

          return getDatabaseCollectionFromRxCollection(
            this.rxDbInstance.collections[propertyName],
            this._options.handleStateUpdateSideEffects,
          );
        },
      },
    ) as Collections;
  }
}
