import type { RxStorage } from 'rxdb';
import { getRxStorageMemory } from 'rxdb/plugins/storage-memory';
import { getLocalstorageMetaOptimizerRxStorage } from 'rxdb-premium/plugins/storage-localstorage-meta-optimizer';
import { getRxStorageSharedWorker } from 'rxdb-premium/plugins/storage-worker';

import {
  isDesktopApp,
  isDevOrTest,
  isDocumentShareApp,
  isTest,
  shouldRunRxDBInMemoryStorageLocally,
} from '../../utils/environment';
import { createCustomHybridRxStorage } from './createCustomHybridRxStorage';
import { getDesktopSQLiteRxStorage } from './DesktopTauriSQLiteStorage';
// TypeScript doesn't understand `?worker&url`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import unsafeSharedWorkerUrl from './storages/storage-worker/storage-worker?worker&url';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Storage = RxStorage<any, any>;

export default function getRxStorage(options: {
  eventEmitter: EventEmitter2;
  isInForeground: boolean;
}): Storage {
  if (isDocumentShareApp) {
    return getRxStorageMemory();
  }
  if (isDesktopApp) {
    return getDesktopSQLiteRxStorage();
  }

  let storage: Storage;

  if (isTest || !('SharedWorker' in window) || (shouldRunRxDBInMemoryStorageLocally && isDevOrTest)) {
    storage = createCustomHybridRxStorage();
  } else {
    /*
      RxDB demands a string URL for the SharedWorker. The recommended approach didn't work at all;
      `new URL('./worker.js', import.meta.url)`

      The next approach would be to import `./filepath?worker&url`. This is supposed to give you a URL string, but we
      found that it instead returns what `?worker` would; a wrapper function for creating a worker.

      Using just `?url` worked in development, but it broke weirdly for production builds. It used a base64 string
      representation of the file content instead of doing an import (and the string's mime-type was `video/mp2t` for
      some reason).

      We could have put the worker file in `web/public` and hardcoded the URL here, but;
      1. The developer workflow would be bad (e.g. reload). So bad that it couldn't easily be automated and would need
        be checked in to git.
      2. It would be bad for caching; we'd need to manually update the filename to cache-bust.
      3. We also found some compilation issues.

      So what we do is use `?worker&url`, and if the result is a function, we inspect its contents and pull out the URL
      using a regular expression.
    */
    let sharedWorkerUrl: string | undefined;
    if (typeof unsafeSharedWorkerUrl === 'string') {
      sharedWorkerUrl = unsafeSharedWorkerUrl;
    } else {
      if (typeof unsafeSharedWorkerUrl !== 'function') {
        // eslint-disable-next-line no-console
        console.error(`Storage Worker URL is unexpectedly typeof ${typeof unsafeSharedWorkerUrl}`);
      }
      const matches = unsafeSharedWorkerUrl
        .toString()
        .match(new RegExp(/Worker\s?\(\s?(['"])([-_./@:%_+.~#?&a-z0-9]+)\1/im));
      if (!matches?.[2]) {
        // eslint-disable-next-line no-console
        console.error('Failed to parse URL from SharedWorker wrapper function', {
          matches,
          unsafeSharedWorkerUrl: unsafeSharedWorkerUrl.toString(),
        });
      } else {
        sharedWorkerUrl = matches[2];
      }
    }

    // If we failed to get the shared worker URL for any reason
    if (!sharedWorkerUrl) {
      storage = createCustomHybridRxStorage();
    } else {
      try {
        // https://rxdb.info/rx-storage-shared-worker.html (chrome://inspect/#workers)
        storage = getRxStorageSharedWorker({
          workerInput: sharedWorkerUrl,
          workerOptions: {
            credentials: 'omit',
            name: 'shared-hybrid-storage-worker',
            type: 'module',
          },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        storage = createCustomHybridRxStorage();
      }
    }
  }

  return getLocalstorageMetaOptimizerRxStorage({ storage });
}
