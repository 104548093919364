import { useEffect, useState } from 'react';

import fallbackPrompts from '../../../copied-from-backend/fallback-prompts.json';
import { AnyDocument, DocumentWithTransientData, PartialDocument } from '../../types';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import makeLogger from '../../utils/makeLogger';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import { globalState } from '../models';
import { resetDocumentSummaryGeneration } from '../stateUpdaters/transientStateUpdaters/documentSummary';
import { generateSummary } from './actions';
import { PromptScopeType, PromptsVersion, PromptType } from './constants';
import { getSummaries } from './summary';
import { type Prompt, Prompts } from './types';
import { transformPrompts } from './utils';

const logger = makeLogger(__filename);

/*
 * HACK: Memoize the API response to avoid "flickering" when a component is
 *  freshly mounted with this hook.
 */
let memoizedDefaultPrompts: Prompts;

export function useDefaultPrompts() {
  const [currentPrompts, setCurrentPrompts] = useState(memoizedDefaultPrompts);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (currentPrompts) {
      return;
    }

    (async function loadDefaultPrompts() {
      try {
        /*
         * The default_prompts endpoint is proxied through the service worker
         * and cached with the stale-while-revalidate policy. We always need
         * prompts to be present, this is the reason we need fallback prompts to
         * also be loaded.
         */
        const result = await requestWithAuth(
          `${getServerBaseUrl()}/reader/api/default_prompts/${PromptsVersion.V2}`,
          {
            method: 'GET',
            credentials: 'include',
            signal,
          },
        );
        memoizedDefaultPrompts = await result.json();
        setCurrentPrompts(memoizedDefaultPrompts);
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          logger.debug('aborted fetching default prompts');
        } else {
          logger.error('failed fetching default prompts', { error });
        }
      }
    })();

    return () => {
      controller.abort();
    };
  }, [currentPrompts]);

  return currentPrompts ? currentPrompts : (fallbackPrompts as Prompts);
}

/**
 * Return the customized prompt or fallback to the default one. In case the
 * current default prompts aren't loaded yet, we return fallback prompts shipped
 * with the application.
 *
 * @param scope
 * @param type
 */
export function useOverrideOrRealPrompt(scope: PromptScopeType, type: PromptType) {
  const selectedScope = useOverrideOrRealPromptScope(scope);
  const maybePrompt = selectedScope?.prompts.find((prompt) => prompt.type === type);
  if (maybePrompt) {
    return maybePrompt;
  }

  throw new Error(
    `Undefined prompt: Make sure to request an existing scope and type: scope=${scope}, type=${type}`,
  );
}

/**
 * Return all user-customized or default prompts for the given scope
 * @param scope
 */
export function useOverrideOrRealPromptScope(scope: PromptScopeType) {
  const [overriddenPrompts, createdPrompts] = globalState((state) => [
    state.persistent.settings.overriddenPrompts2 ?? state.persistent.settings.overriddenPrompts,
    state.persistent.settings.createdPrompts,
  ]);
  const defaultPrompts = useDefaultPrompts();
  const prompts = transformPrompts(defaultPrompts, overriddenPrompts, createdPrompts);
  return prompts.data.find((s) => s.type === scope);
}

/**
 * Returns a flattened list of all prompts for the given set of scopes
 * @param scopes
 */
export function useFlatOverrideOrRealPromptScopes(...scopes: PromptScopeType[]) {
  const [overriddenPrompts, createdPrompts] = globalState((state) => [
    state.persistent.settings.overriddenPrompts2 ?? state.persistent.settings.overriddenPrompts,
    state.persistent.settings.createdPrompts,
  ]);
  const defaultPrompts = useDefaultPrompts();
  const prompts = transformPrompts(defaultPrompts, overriddenPrompts, createdPrompts);

  return scopes.reduce<Prompt[]>((filteredPrompts, scopeType) => {
    const scope = prompts.data.find((s) => s.type === scopeType);
    if (scope) {
      filteredPrompts.push(...Array.from(scope.prompts.values()));
    }
    return filteredPrompts;
  }, []);
}

/**
 * A document summary isn't just a simple field, it is a field that might
 * contain a value extracted from the document, or a generated summary which is
 * either created manually by the user, or via the auto-summarization feature
 * for power users that bring their own OpenAI key.
 *
 * This hook is a convenience hook, to hide the complexity of dealing with
 * changes to those summaries, as well as handling undo actions, etc. Under all
 * circumstances, it will return a valid summary {@link String}, even the value
 * might be blank.
 */
export function useSummary(
  document:
    | DocumentWithTransientData
    | PartialDocument<AnyDocument, 'summary' | 'generated_summary' | 'category' | 'id' | 'overrides'>,
) {
  const { generatedSummary, summary } = getSummaries(document);
  const isGenerated = Boolean(generatedSummary);
  const isGenerating = globalState((state) => state.isDocumentSummaryGenerating);
  const summarizePrompt = useOverrideOrRealPrompt(
    PromptScopeType.Automatic,
    PromptType.SummarizeDocument,
  );

  useEffect(() => {
    resetDocumentSummaryGeneration('unknown');
  }, [document.id]);

  const generate = () => generateSummary(document, summarizePrompt);

  return { summary, generate, isGenerating, isGenerated, summarizePrompt };
}
