import {
  clone,
  FilledMangoQuery,
  getPrimaryFieldOfPrimaryKey,
  MangoQuerySelector,
  MangoQuerySortPart,
  randomCouchString,
  RxDocumentData,
  RxJsonSchema,
} from 'rxdb';

// eslint-disable-next-line import/no-cycle
import {
  getIndexId,
  isPlainObject,
  NON_IMPLEMENTED_OPERATOR_QUERY_BATCH_SIZE,
  PARAM_KEY,
} from './sqlite-helpers';
import { RxStorageInstanceSQLite } from './sqlite-storage-instance';
import type { SQLitePreparedQuery } from './sqlite-types';

function getJsonExtract(primaryPath: string, key: string) {
  if (primaryPath === key) {
    return 'id';
  } else {
    const keySafe = key
      .replaceAll('\\', '') // unescape all the escaped special chars
      .replaceAll("'", "''"); // double up single quote to escape single quote in SQL.

    return `JSON_EXTRACT(data, '$.${keySafe}')`;
  }
}

function getRegexQuerySelectorSQL({
  primaryPath,
  prePath,
  value,
}: { primaryPath: string; prePath: string; value: unknown }) {
  if (typeof value !== 'string') {
    throw new Error('$regex value is not a string');
  }
  const regex = value as string;
  // TODO: right now this only parses '.*' regex matchers, other special regex characters are broken.
  // TODO: better protect against SQL injection. worst that can happen is user nukes their own DB i guess.
  // TODO: add full text search indexes for fields that are commonly queried using $regex/LIKE?
  const searchString = regex
    .replaceAll('\\', '') // unescape all the escaped special chars
    .replaceAll("'", "''") // double up single quote to escape single quote in SQL.
    .replaceAll('@', '@@') // escape @ as @@ first, so that later @-escapes don't get escaped.
    .replaceAll('%', '@%') // escape percent signs which are arbitrary length wildcards
    .replaceAll('.*', '%') // turn generic '.*' regex matchers into equivalent SQL LIKE % matchers
    .replaceAll('_', '@_'); // escape underscores, which are single character wildcards
  return `${getJsonExtract(primaryPath, prePath)} LIKE '%${searchString}%' ESCAPE '@'`;
}

/**
 * NOTES:
 * - 'deleted' is implicitly appended to the end of each list of index fields
 * - order of fields is crucial here for index selection. see https://www.sqlite.org/optoverview.html "Index Term Usage Examples"
 * - generally you want to order fields as follows:
 *   first all the fields which are checked for equality and chained with AND.
 *   then the fields in the ORDER BY clause.
 *   then the fields that are checked with inequality (<, >, <=, >=) or chained with OR.
 * - these indexes are optimized to make our builtin queries as fast as possible.
 *   you can see those builtin queries being generated chiefly in convertQueryToRxDBQuery().
 */

// For some reason, on desktop this function cannot be imported from rxdb-premium/plugins/storage-sqlite/sqlite-query.ts.
// So we have to duplicate it here.
function mangoQuerySortToSQL(primaryPath: string, sorting: MangoQuerySortPart[]): string {
  return `ORDER BY ${sorting
    .map((sortPart) => {
      const [path, direction] = Object.entries(sortPart)[0];
      return `${getJsonExtract(primaryPath, path)} ${direction.toUpperCase()}`;
    })
    .join(', ')}`;
}

// NOTE: identical to rxdb-premium/src/storage-sqlite/sqlite-query.ts:mangoQuerySelectorToSQL() except for the parts marked ADDED BY MITCH
// TODO: this is a cheap version of a fork. perhaps we should actually fork storage-sqlite.
const LOGICAL_MANGO_OPERATORS = ['$or', '$and'];

function customMangoQuerySelectorToSQL<RxDocType>(
  schema: RxJsonSchema<RxDocumentData<RxDocType>>,
  selector: MangoQuerySelector<RxDocType>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutableParams: any[],
  prePath?: string,
): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const primaryPath = getPrimaryFieldOfPrimaryKey(schema.primaryKey) as any;
  const stringParts = Object.entries(selector).map(([key, value]) => {
    if (key.startsWith('$')) {
      // is operator
      if (LOGICAL_MANGO_OPERATORS.includes(key)) {
        // logical operator
        const sqlCombinator = ` ${key.substring(1).toUpperCase()} `;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const logicalParts = value.map((v: any) =>
          customMangoQuerySelectorToSQL(schema, v, mutableParams, prePath),
        );
        if (logicalParts.length > 1) {
          return `(${logicalParts.join(sqlCombinator)})`;
        } else {
          return logicalParts.join(sqlCombinator);
        }
      } else {
        // query selector operator
        if (!prePath) {
          throw new Error(`cannot have selector operator on the top level ${key}`);
        }
        switch (key) {
          case '$eq':
            if (value === null) {
              return `${getJsonExtract(primaryPath, prePath)} IS NULL`;
            } else {
              mutableParams.push(value);
              return `${getJsonExtract(primaryPath, prePath)}=${PARAM_KEY}`;
            }
          case '$ne':
            if (value === null) {
              return `${getJsonExtract(primaryPath, prePath)} IS NOT NULL`;
            } else {
              mutableParams.push(value);
              const baseSQLOperator = `${getJsonExtract(primaryPath, prePath)}!=${PARAM_KEY}`;
              if (value === null) {
                return baseSQLOperator;
              } else {
                /**
                 * The field might be optional so it can be NULL and must still match
                 * the $ne operation.
                 */
                return `(${baseSQLOperator} OR (${getJsonExtract(primaryPath, prePath)} IS NULL))`;
              }
            }
          case '$gt':
            mutableParams.push(value);
            return `${getJsonExtract(primaryPath, prePath)}>${PARAM_KEY}`;
          case '$gte':
            mutableParams.push(value);
            return `${getJsonExtract(primaryPath, prePath)}>=${PARAM_KEY}`;
          case '$lt':
            mutableParams.push(value);
            return `${getJsonExtract(primaryPath, prePath)}<${PARAM_KEY}`;
          case '$lte':
            mutableParams.push(value);
            return `${getJsonExtract(primaryPath, prePath)}<=${PARAM_KEY}`;
          case '$exists':
            if (value) {
              /**
               * SQLite has no JSON_EXISTS method,
               * but we can ensure existence of a field
               * by comparing it to a random string that would never match.
               */
              mutableParams.push(`rand-${randomCouchString(10)}`);
              return `${getJsonExtract(primaryPath, prePath)}!=${PARAM_KEY}`;
            } else {
              return `${getJsonExtract(primaryPath, prePath)} IS NULL`;
            }
          case '$in':
            value.forEach((p) => mutableParams.push(p));
            return `${getJsonExtract(primaryPath, prePath)} IN (${new Array(value.length)
              .fill(PARAM_KEY)
              .join(',')})`;
          case '$nin':
            mutableParams.push(value);
            return `${getJsonExtract(primaryPath, prePath)} NOT IN (${PARAM_KEY})`;
          // --- ADDED BY MITCH ---
          case '$not': {
            const supportedNotKeys = ['$regex'];
            const conditionKey = Object.keys(value).find((key) => supportedNotKeys.includes(key));

            if (!conditionKey) {
              const err = new Error(
                `$not must have a condition key matching one of: ${supportedNotKeys.join(', ')}`,
              );
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (err as any).operator = key;
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (err as any).isNonImplementedOperatorError = true;
              throw err;
            }

            const conditionValue = value[conditionKey];

            // Only $regex for now
            const regexValue = conditionValue.toString();
            return `NOT (${getRegexQuerySelectorSQL({ primaryPath, prePath, value: regexValue })})`;
          }
          case '$options':
            return 'true'; // ignore regex flags for now
          case '$regex': {
            return getRegexQuerySelectorSQL({ primaryPath, prePath, value });
          }
          // --- END: ADDED BY MITCH ---
          default: {
            const err = new Error(`operator ${key} not implemented`);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (err as any).operator = key;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (err as any).isNonImplementedOperatorError = true;
            throw err;
          }
        }
      }
    } else if (!isPlainObject(value)) {
      // is is an $eq shortcut like { foo: 'bar'}
      mutableParams.push(value);
      return `${getJsonExtract(primaryPath, key)}=${PARAM_KEY}`;
    } else {
      // is not an operator
      return customMangoQuerySelectorToSQL(
        schema,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value as any,
        mutableParams,
        key,
      );
    }
  });
  return `(${stringParts.join(' AND ')})`;
}

export function customPrepareSQLiteQuery<RxDocType>(
  instance: RxStorageInstanceSQLite<RxDocType>,
  mutateableQuery: FilledMangoQuery<RxDocType>,
): SQLitePreparedQuery<RxDocType> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const primaryPath = getPrimaryFieldOfPrimaryKey(instance.schema.primaryKey) as any;
  /**
   * If no limit given, we have to set it to -1
   * to ensure OFFSET still works.
   * @link https://stackoverflow.com/a/19676495/3443137
   */
  const limitString = mutateableQuery.limit ? `LIMIT ${mutateableQuery.limit}` : 'LIMIT -1';
  const skipString = mutateableQuery.skip ? `OFFSET ${mutateableQuery.skip}` : '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let mutableParams: any[] = [];
  let fullQueryString = '';
  let nonImplementedOperator: string | undefined;

  let indexedBy = '';
  if (mutateableQuery.index) {
    const indexId = getIndexId(
      instance.databaseName,
      instance.collectionName,
      instance.schema,
      mutateableQuery.index,
    );
    indexedBy = `INDEXED BY "${indexId}"`;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const querySortPart = mangoQuerySortToSQL(primaryPath, mutateableQuery.sort as any);
  try {
    let whereClauseSelector = customMangoQuerySelectorToSQL<RxDocType>(
      instance.schema,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mutateableQuery.selector as any,
      mutableParams,
    );
    if (whereClauseSelector !== '()') {
      whereClauseSelector = ` WHERE ${whereClauseSelector} `;
    } else {
      whereClauseSelector = '';
    }

    fullQueryString =
      // eslint-disable-next-line no-useless-concat
      `${indexedBy} ${whereClauseSelector}${querySortPart} ${limitString} ${skipString} ` + `;`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err.isNonImplementedOperatorError) {
      mutableParams = [];
      nonImplementedOperator = err.operator;
      fullQueryString =
        // eslint-disable-next-line no-useless-concat
        `${indexedBy} ${querySortPart} ` + `LIMIT ${NON_IMPLEMENTED_OPERATOR_QUERY_BATCH_SIZE} `; // TODO use custom batchSize
    } else {
      throw err;
    }
  }
  const prepared: SQLitePreparedQuery<RxDocType> = {
    schema: instance.schema,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mangoQuery: clone(mutateableQuery) as any,
    sqlQuery: {
      query: fullQueryString,
      params: mutableParams,
      context: {
        method: 'prepareQuery',
        data: {},
      },
    },
    queryWithoutSort: fullQueryString.replace(querySortPart, ' '),
    nonImplementedOperator,
  };

  return prepared;
}
