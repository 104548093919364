import { createRxSchema, defaultHashSha256 } from 'rxdb';
import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb';

import documents from './defineSchema/documents';
import { DocumentIndexesCommonForWebAndMobileAndDesktop } from './indexes';
import { getHybridRxStorage, RxStorageHybrid } from './storages/storage-hybrid';

const documentsSchema = createRxSchema(documents.getSchema().documents.schema, defaultHashSha256);

export function createCustomHybridRxStorage(): RxStorageHybrid {
  return getHybridRxStorage({
    storage: getRxStorageIndexedDB(),
    wrappedStorageSchemaCompression: false,
    wrappedStorageSchema: {
      documents: documentsSchema.jsonSchema,
    },
    wrappedStorageIndexes: {
      documents: DocumentIndexesCommonForWebAndMobileAndDesktop as string[][],
    },
  });
}
